import React, { CSSProperties, FC, useEffect, useState } from 'react'
import cn from 'classnames'

import { observer } from 'mobx-react'
import { useStore } from 'store'

import moon from 'sources/images/lightMoon.svg'
import darkMoon from 'sources/images/darkMoon.svg'
import sun from 'sources/images/authsun.svg'
import darkSun from 'sources/images/darkSun.svg'

import styles from './styles.module.scss'

interface IProps {
  style?: CSSProperties
}

//Resize
export const ThemeSwitch: FC<IProps> = observer(({style}) => {
    const {theme} = useStore();

    const setTheme = (e: React.MouseEvent<HTMLDivElement>,type: 'dark' | 'light') => {
        e.stopPropagation();
        const htmlEl = document.getElementsByTagName('html')[0];
        htmlEl.dataset.theme = type;
        localStorage.setItem('theme', type);
        theme.setTheme(type);
    }

    return (
        <div className={cn({
            [styles.switchTheme]: true,
            [styles.switchThemeDark]: theme.theme === 'dark',
        })}
             style={style}>
            <div className={styles.oval}/>
            <div className={styles.sun}
                 onClick={(e) => setTheme(e,'light')}>
                <img src={theme.theme === 'dark' ? darkSun : sun} alt="sun"/>
            </div>
            <div className={styles.moon}
                 onClick={(e) => setTheme(e,'dark')}>
                <img src={theme.theme === 'dark' ? darkMoon : moon} alt="moon"/>
            </div>
        </div>
    );
})
