import React from 'react'
import { observer } from 'mobx-react'
import Button from 'components/Button'
import ImageLink from 'components/ImageLink'
import Modal from 'components/Modal'

import { useStore } from 'store'

import chrome from 'sources/images/chrome.png'
import firefox from 'sources/images/firefox.png'

import styles from './styles.module.scss'

const ModalInstallMetaMask: React.FC = observer(() => {
  const { main } = useStore()
  return (
    <Modal
      blockOutsideClose
      open={main.isOpenInstallMetaMaskModal}
      size={'small'}
      children={
        <div className={styles.content}>
          <div className={styles.headText}>Warning</div>
          <div className={styles.bodyText}>
            You need to install Meta Mask extension. <br/> Choose your
            browser and install it
          </div>
          <div className={styles.linksWrap}>
            <ImageLink
              url="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en"
              src={chrome}
              text="Chrome"
            />
            <ImageLink
              url="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/"
              src={firefox}
              text="Firefox"
            />
          </div>
          <Button
            onClick={() => main.setOpenInstallMetaMaskModal()}
            style={{
              maxWidth: '555px',
              width: '100%',
              height: '50px',
              background:
                'linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%)'
            }}
            styleText={{ color: '#ffffff' }}
            btnStyle="classic"
            text="Close"
          />
        </div>
      }
    />
  )
})

export default ModalInstallMetaMask
