import React, { CSSProperties, useEffect, useState } from 'react'

import cn from 'classnames'

import { ReactComponent as Close } from 'sources/images/close.svg'

import styles from './styles.module.scss'

interface IProps {
  open: boolean //-◄-Open modal
  children: React.ReactNode //-◄-Body in modal
  onClose?: Function //-◄-Close function
  size?:
    | 'big'
    | 'medium'
    | 'small'
    | 'superSmall'
    | 'responsive'
    | 'responsiveMedium' //-◄-Custom width
  closeButton?: boolean //-◄-Show close icon or not?
  closeButtonStyle?: CSSProperties
  boxStyle?: CSSProperties
  contentStyle?: CSSProperties //-◄-Modal style
  blockOutsideClose?: boolean //-◄-Don`t close modal, if user click outside modal
  withoutBlurBox?: boolean //-◄-Whether to show background blur?
  freeBlock?: boolean //-◄-Unset style in body
  zeroPadding?: boolean
  additionalNode?: React.ReactNode | JSX.Element
  additionalStyleMainBlock?: boolean
  onClickContentBlock?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void
  withAnimation?: boolean
}

const Modal = React.forwardRef<HTMLDivElement, IProps>(
  (
    {
      open,
      children,
      onClose,
      size = 'medium',
      closeButton,
      closeButtonStyle,
      contentStyle,
      blockOutsideClose,
      boxStyle,
      withoutBlurBox,
      freeBlock,
      zeroPadding,
      additionalNode,
      additionalStyleMainBlock,
      onClickContentBlock,
      withAnimation,
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false)
    let closeTimer!: NodeJS.Timeout

    useEffect(() => {
      if (!withAnimation) return
      const timer = setTimeout(() => {
        setVisible(open)
      }, 50)
      return () => {
        clearTimeout(timer)
        clearTimeout(closeTimer)
      }
    }, [open])

    const closeModal = () => {
      setVisible(prev => !prev)
      if (withAnimation) {
        setTimeout(() => {
          onClose && onClose()
        }, 700)
      } else {
        onClose && onClose()
      }
    }

    return (
      <>
        {/*---▼▼▼--- CUSTOM MODAL ---▼▼▼---*/}
        {open && (
          <div
            id={'custom-modal'}
            ref={ref}
            style={boxStyle}
            className={cn({
              [styles.box]: true,
              [styles.boxHidden]: withAnimation,
              [styles.boxVisible]: visible,
              [styles.withoutBlurBox]: withoutBlurBox,
              [styles.boxAdditionalStyle]: additionalStyleMainBlock,
            })}
            onClick={() => !blockOutsideClose && onClose && closeModal()}
          >
            <div
              style={contentStyle}
              onClick={event => {
                event.stopPropagation()
                onClickContentBlock && onClickContentBlock(event)
              }}
              className={cn({
                [styles.content]: true,
                [styles.contentBig]: size === 'big',
                [styles.contentSmall]: size === 'small',
                [styles.contentMedium]: size === 'medium',
                [styles.contentSuperSmall]: size === 'superSmall',
                [styles.contentResponsive]: size === 'responsive',
                [styles.contentResponsiveMedium]: size === 'responsiveMedium',
                [styles.freeBlock]: freeBlock,
                [styles.zeroPadding]: zeroPadding,
              })}
            >
              {/*---▼▼▼--- CLOSE ICON ---▼▼▼---*/}
              {closeButton && (
                <Close
                  className={styles.close}
                  style={closeButtonStyle}
                  onClick={() => onClose && closeModal()}
                />
              )}
              {/*---▼▼▼--- BODY ---▼▼▼---*/}
              {children}
            </div>
            {additionalNode}
          </div>
        )}
      </>
    )
  }
)

export default Modal
