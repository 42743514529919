import { observable, action, makeAutoObservable } from 'mobx'
import { api } from '../utils'
import { toast } from '../App'
import LoadingStore from './loading'

interface INFTInfo {
	Owner: string,
	Creator: string,
	LastPrice: number | null,
	Name: string,
	Description: string,
	OpenSeaLink: string,
	ImageLink: string
}

const innitInfo = {
	Owner: '',
	Creator: '',
	LastPrice: 0,
	Name: '',
	Description: '',
	OpenSeaLink: '',
	ImageLink: ''
}

class NFT {
	nftModalOpen: boolean = false
	nftInfo: INFTInfo = innitInfo

	
	constructor() {
		makeAutoObservable(this)
	}

	
	setNftModalOpen(value: boolean) {
		this.nftModalOpen = value
	}

	
	setNftInfo(value: INFTInfo) {
		this.nftInfo = value
	}

	
	async getNFTInfo(userId: string) {
		const response = await api.get(`api/Account/getNFTInfo?userId=${userId}`)
		if (response.status !== 200)
			throw new Error(
				response.data && response.data.Description
					? response.data.Description
					: 'Error retrieving user profiles'
			)
		return response.data
	}

	
	async openNFTInfoModal(userId: string) {
		try {
			LoadingStore.setLoading(true)
			const nftInfo = await this.getNFTInfo(userId)
			this.setNftInfo(nftInfo)
			this.setNftModalOpen(true)
		} catch (e) {
			toast({
				type: 'warning',
				message: 'Error loading NFT information. Please, try again.'
			})
		} finally {
			LoadingStore.setLoading(false)
		}
	}
}

export default new NFT()
