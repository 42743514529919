import React, { FC } from 'react'
import { ModalView } from 'components/ModalView'
import Button from 'components/Button'

import { observer } from 'mobx-react'
import { useStore } from 'store'

import styles from './styles.module.scss'

interface IProps {}

export const NFTInfoModal: FC<IProps> = observer(() => {
	const { nft } = useStore()

	const closeModal = () => {
		nft.setNftModalOpen(false)
	}
	return (
		<ModalView
			onClose={closeModal}
			open={nft.nftModalOpen}
			closeButton={true}
			children={
				<div className={styles.nftContainer}
						 style={{ backgroundImage: `url(${nft.nftInfo.ImageLink})`}}>
					<div className={styles.top}>
						<div className={styles.priceBlock}>
							<div className={styles.priceTitle}>Listing Price</div>
							<div className={styles.price}>{`${nft.nftInfo.LastPrice ? nft.nftInfo.LastPrice : 0} ETH`}</div>
						</div>
					</div>
					<div className={styles.bottom}>
						<div className={styles.bottomLeftBlock}>
							<div className={styles.nftName}>{nft.nftInfo.Name}</div>
							<div className={styles.nftDescription}>{nft.nftInfo.Description}</div>
							<div className={styles.btnBlock}>
								<Button
									style={{
										width: '100%',
										minWidth: '200px',
										height: '50px',
										background: 'linear-gradient(#2E2B63, #2E2B63)',
										boxShadow: '0px 7px 25px rgba(0, 0, 0, 0.0563538)',
									}}
									styleText={{ color: '#ffffff' }}
									btnStyle="classic"
									text="Open on OpenSea"
									onClick={() => window.open(nft.nftInfo.OpenSeaLink, '_blank')}
									blueHover
								/>
							</div>
						</div>
						<div className={styles.bottomRightBlock}>
							<div className={styles.nft}>NFT</div>
							<div className={styles.nftOwner}>
								<div>Owned by</div>
								<div>{nft.nftInfo.Owner}{nft.nftInfo.Owner}</div>
							</div>
							<div className={styles.nftCreator}>
								<div >Created by</div>
								<div>{nft.nftInfo.Creator}</div>
							</div>
						</div>
					</div>
				</div>
			}
		/>
	);
})
