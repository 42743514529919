import React, { CSSProperties, useEffect, useState } from 'react'
import cn from 'classnames'
import { IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

import { ReactComponent as Close } from 'sources/images/close.svg'

import styles from './styles.module.scss'

interface IProps {
  open: boolean
  children: React.ReactElement
  onClose?: Function
  closeButton?: boolean
  closeButtonStyle?: CSSProperties
  closeButton360?: boolean
  closeButton3D?: boolean
  style?: CSSProperties
  blockOutsideClose?: boolean
  withoutBlurBox?: boolean
  unmountModal?: boolean
  withAnimation?: boolean
}

export const ModalView: React.FC<IProps> = (
  {
    open,
    children,
    onClose,
    closeButton,
    closeButtonStyle,
    closeButton360,
    closeButton3D,
    style,
    blockOutsideClose,
    withoutBlurBox,
    unmountModal = true,
    withAnimation
  }) => {
  const [visible, setVisible] = useState(false)
  let closeTimer!: NodeJS.Timeout

  useEffect(() => {
    if (!withAnimation) return
    const timer = setTimeout(() => {
      setVisible(open)
    }, 50)
    return () => {
      clearTimeout(timer)
      clearTimeout(closeTimer)
    }
  }, [open])

  const modalElement = (
    <div
      style={{ display: open ? 'flex' : 'none' }}
      id={'custom-modal-view'}
      className={cn({
        [styles.box]: true,
        [styles.boxHidden]: withAnimation,
        [styles.boxVisible]: visible,
        [styles.withoutBlurBox]: withoutBlurBox,
        [styles.withoutCloseButton360]: closeButton360
      })}
      onClick={() => !blockOutsideClose && onClose && onClose()}
    >
      <div
        style={style}
        onClick={event => event.stopPropagation()}
        className={cn({
          [styles.content]: true
        })}
      >
        {closeButton && (
          <Close
            style={closeButtonStyle}
            className={styles.close}
            onClick={() => onClose && onClose()}
          />
        )}

        {closeButton3D ?
          <div className={styles.close3D}>
            <IconButton
              disableRipple
              onClick={() => onClose && onClose()}
              sx={{
                width: '30px',
                height: '30px',
                backgroundColor: 'white',
                padding: '0',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.5)'
                }
              }}
            >
              <ClearIcon style={{ color: '#2e2b63', fontSize: '20px' }}/>
            </IconButton>
          </div> : null}

        {/*{closeButton360 && (*/}
        {/*  <div onClick={() => onClose && onClose()} className={styles.close360Box}>*/}
        {/*    <Close />*/}
        {/*  </div>*/}
        {/*)}*/}
        {children}
      </div>
    </div>
  )

  return <>{unmountModal ? open && modalElement : modalElement}</>
}
