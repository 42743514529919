import React, { FC } from 'react'

import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import cn from 'classnames'
import { useDisconnect } from 'wagmi'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import { toast } from 'App'
import { ThemeSwitch } from 'components/Header/ThemeSwitch'

import { useStore } from 'store'

import { ReactComponent as Auth } from 'sources/imagesPWA/drawerMenu/auth.svg'
import { ReactComponent as AuthToken } from 'sources/imagesPWA/drawerMenu/authToken.svg'
import { ReactComponent as FAQ } from 'sources/imagesPWA/drawerMenu/FAQ.svg'
import { ReactComponent as HowItWorks } from 'sources/imagesPWA/drawerMenu/howItWorks.svg'
import { ReactComponent as Invite } from 'sources/imagesPWA/drawerMenu/invite.svg'
import { ReactComponent as LogOut } from 'sources/imagesPWA/drawerMenu/logOut.svg'
import { ReactComponent as Marketplace } from 'sources/imagesPWA/drawerMenu/marketplace.svg'
import { ReactComponent as VideoChat } from 'sources/imagesPWA/drawerMenu/videoChat.svg'
import { ReactComponent as Whitepapper } from 'sources/imagesPWA/drawerMenu/whitepapper.svg'

import styles from './styles.module.scss'

enum MenuItem {
  howItWorks = 'How it Works',
  whitepaper = 'Whitepaper',
  authToken = 'AUTH Token',
  marketplace = 'Marketplace',
  videoChat = 'Video Chat',
  invite = 'Invite',
  FAQ = 'FAQ',
  logOut = 'Log out',
}

const menu = [
  {
    name: MenuItem.howItWorks,
    icon: <HowItWorks className={cn(styles.howItWorks, styles.icon)} />,
    authorizationRequired: false,
  },
  {
    name: MenuItem.whitepaper,
    icon: <Whitepapper className={cn(styles.whitepaper, styles.icon)} />,
    authorizationRequired: false,
  },
  {
    name: MenuItem.authToken,
    icon: <AuthToken className={cn(styles.authToken, styles.icon)} />,
    authorizationRequired: true,
  },
  {
    name: MenuItem.marketplace,
    icon: <Marketplace className={cn(styles.marketplace, styles.icon)} />,
    authorizationRequired: true,
  },
  // {
  //   name: MenuItem.videoChat,
  //   icon: <VideoChat className={cn(styles.videoChat, styles.icon)}/>,
  //   authorizationRequired: true
  // },
  {
    name: MenuItem.invite,
    icon: <Invite className={cn(styles.invite, styles.icon)} />,
    authorizationRequired: true,
  },
  {
    name: MenuItem.FAQ,
    icon: <FAQ className={cn(styles.FAQ, styles.icon)} />,
    authorizationRequired: false,
  },
  {
    name: MenuItem.logOut,
    icon: <LogOut className={cn(styles.logOut, styles.icon)} />,
    authorizationRequired: true,
  },
]

interface IProps {}

export const MenuList: FC<IProps> = observer(props => {
  const navigate = useNavigate()
  const { authToken, profile } = useStore()
  const { disconnect: disconnectWalletConnect } = useDisconnect()

  const isAuthorizedProfile = profile.isAuthorizedProfile()

  const handleClick = (value: MenuItem) => {
    if (value === MenuItem.logOut) {
      return logOut()
    }
  }

  const logOut = () => {
    profile.clearSessionProfileData()
    profile.clearSessionTokenData()
    disconnectWalletConnect()

    navigate('/')
    toast({
      type: 'success',
      message: 'You have logged off successfully',
    })
  }

  return (
    <List>
      <div className={styles.authPrice}>
        <Auth className={styles.icon} />1 AUTH =$
        {authToken.AUTHPrice.USDPriceForAUTH.toFixed(3)}
      </div>
      {menu.map(item => {
        if (!isAuthorizedProfile && item.authorizationRequired) {
          return null
        }

        return (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              onClick={() => handleClick(item.name)}
              sx={{
                paddingLeft: '24px',
                paddingRight: '24px',
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: '36px',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                sx={{
                  '& span': {
                    color: 'var(--textColor)',
                    fontSize: '0.9rem',
                    lineHeight: 1.2,
                    fontFamily: 'Lato',
                    fontWeight: 600,
                    fontStyle: 'normal',
                    letterSpacing: 'normal',
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        )
      })}
      <ThemeSwitch style={{ margin: '8px 22px' }} />
    </List>
  )
})
