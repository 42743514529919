import React from 'react'
import styles from './styles.module.scss'

interface IProps {
  url: string
  src: string
  text: string
}

const ImageLink: React.FC<IProps> = ({ url, src, text }) => {
  return (
    <a className={styles.link} rel="noreferrer noopener" target="_blank" href={url}>
      <img src={src} alt="chrome" className={styles.img} />
      <div>{text}</div>
    </a>
  )
}

export default ImageLink
