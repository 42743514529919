import { convertToRaw, EditorState } from 'draft-js'
import { ethers } from 'ethers'

import { toast } from 'App'
import { Block as EditorBlockType } from 'components/CustomEditor/util/constants'

import { ICreateComment, IIpfsFiles, ISendPublication } from 'models'

import AuthToken from '../../store/authToken'
import PaymentCurrencyStore from 'store/paymentCurrency'
import ProfileStore from 'store/profile'
import WalletConnectStore from 'store/walletConnect'

import AuthIcon from 'sources/images/authToken.png'

import { AuthCurrency, LoginProvider } from '../const'
import {
  getChainIdFromPaymentCurrency,
  getPostTextFromEditorState,
  getPostTextFromEditorStateIgnoreAtomicImageBlocks,
} from '../functions'
import { ipfs } from '../ipfs'
import { getAuthencityObjectERC721AndUserWallet } from './ERC721'

const { ethereum } = window

export const getGasPrice = async (dataAsSting: string) => {
  try {
    if (!isAvailableBlockchainProviderToast()) return
    const { AuthencityObjectERC721, userWallet, chainId } =
      await getAuthencityObjectERC721AndUserWallet()

    if (
      getChainIdFromPaymentCurrency(PaymentCurrencyStore.currency) !== chainId
    ) {
      toast({
        type: 'warning',
        message: 'The selected network does not match the currency',
      })

      return
    }

    if (!AuthencityObjectERC721) {
      toast({
        type: 'warning',
        message: 'Choose the Ethereum or Polygon network',
      })

      return
    }

    // const tokenIdPrice = 0
    // const tokenURIPrice = `${process.env.REACT_APP_LINK_OBJECT_TOKEN_URI}${tokenIdPrice}`
    // const tokenTypePrice = 'data'
    //
    // const gasCount =
    //   await AuthencityObjectERC721.mintWithTokenObjectData.estimateGas(
    //     userWallet, //to - the address of the wallet
    //     tokenIdPrice, //tokenId - Total objects sum + 1
    //     tokenURIPrice.toString(), //tokenURI
    //     tokenTypePrice, //tokenType - unique name (Can be any)
    //     dataAsSting, //tokenData - publication text + file links in string
    //     '', //tokenKey
    //     '' //databaseKey
    //   )

    const gasCount = await AuthToken.getEstimateGas(
      userWallet,
      dataAsSting.length
    )

    const estimate = 1.5
    return { price: `${(Number(gasCount) * 0.00000001 * estimate).toFixed(6)}` }
  } catch (e) {
    console.log('e', e)
  }
}

export const isAvailableMMProvider = () => {
  return !!(
    ProfileStore.getLoginProvider() === LoginProvider.Metamask && ethereum
  )
}

export const isAvailableWCProvider = () => {
  return !!(
    ProfileStore.getLoginProvider() === LoginProvider.WalletConnect &&
    WalletConnectStore.provider?.connected
  )
}

export const isAvailableBlockchainProvider = () => {
  if (ProfileStore.getLoginProvider() === LoginProvider.Metamask && !ethereum) {
    return false
  }

  if (
    ProfileStore.getLoginProvider() === LoginProvider.WalletConnect &&
    !WalletConnectStore.provider?.connected
  ) {
    return false
  }

  return true
}

export const isAvailableBlockchainProviderToast = () => {
  const toastMessage = () => {
    toast({
      type: 'warning',
      message:
        'This feature is not available because you are not using MetaMask',
    })
  }

  const profileLoginProvider = ProfileStore.getLoginProvider()

  if (
    profileLoginProvider !== LoginProvider.Metamask &&
    profileLoginProvider !== LoginProvider.WalletConnect
  ) {
    toastMessage()
    return false
  }

  if (profileLoginProvider === LoginProvider.Metamask && !ethereum) {
    toastMessage()
    return false
  }

  // TODO: check how make it work
  else if (
    profileLoginProvider !== LoginProvider.WalletConnect &&
    profileLoginProvider !== LoginProvider.Metamask
  ) {
    // if (
    //   profileLoginProvider === LoginProvider.WalletConnect &&
    //   !WalletConnectStore.provider?.connected
    // ) {
    toastMessage()
    return false
  }

  return true
}

export const isSelectedCorrectCurrency = async (currency: AuthCurrency) => {
  let chainId = null

  const profileLoginProvider = ProfileStore.getLoginProvider()

  if (profileLoginProvider === LoginProvider.Metamask && window.ethereum) {
    chainId = await window.ethereum.request({ method: 'eth_chainId' })
  }

  if (
    profileLoginProvider === LoginProvider.WalletConnect &&
    WalletConnectStore.connected
  ) {
    chainId = WalletConnectStore.provider?.chainId
  }

  if (getChainIdFromPaymentCurrency(currency) !== Number(chainId)) {
    toast({
      type: 'warning',
      message: 'The selected network does not match the currency',
    })

    return false
  }

  return true
}

const getFileData = (field: IIpfsFiles) => {
  let data = `${field.FileType}: ${field.FileLink}; `

  if (field.DefaultVideoPreview || field.VideoPreview) {
    data += `${field.FileType}_preview: ${
      field.VideoPreview ? field.VideoPreview : field.DefaultVideoPreview
    }; `
  }

  if (
    field.FileType === 'video' ||
    field.FileType === 'audio' ||
    field.FileType === 'stream'
  ) {
    if (field.VideoAudioTitle) {
      data += `${field.FileType}_title: ${getPostTextFromEditorState(
        field.VideoAudioTitle
      )}; `
    }
    if (field.VideoAudioDescription) {
      data += `${field.FileType}_description: ${getPostTextFromEditorState(
        field.VideoAudioDescription
      )}; `
    }
  }

  return data
}

const getPostText = (editorData: EditorState, ignoreAtomicImage?: boolean) => {
  if (ignoreAtomicImage) {
    return getPostTextFromEditorStateIgnoreAtomicImageBlocks(editorData)
  } else {
    return getPostTextFromEditorState(editorData)
  }
}

// Need check getPriceFileTestLink
export const getPostAsString = async (
  list: ISendPublication[],
  config?: { getPrice?: boolean; id?: number }
) => {
  let getPriceFileTestLink =
    'test/test/test/test/test/test/test/test/test/test/test/test/test/test/test' //-◄-If "Get Price" get test link
  let sendData = ''
  await Promise.all(
    list.map(async (value: ISendPublication, idx: number) => {
      const postText = value.Text
        ? value.Text
        : getPostText(value.EditorData, value.IsBlogpost)
      const superpostPublicText = value.PublicText
        ? `Superpost public text: ${getPostTextFromEditorState(
            value.PublicText
          )}; `
        : ''
      sendData += `Publication: ${config?.id || idx}; Nickname: @${
        ProfileStore.selectedUser.NickName
      }; ${postText && `Text: ${postText}; `}`
      sendData += superpostPublicText

      const { entityMap, blocks } = convertToRaw(
        value.EditorData.getCurrentContent()
      )
      for (const key of Object.keys(entityMap)) {
        const entity = entityMap[key]
        if (entity.type === 'draft-js-video-plugin-video') {
          console.log('video', entity.data.src)

          sendData += `video: ${entity.data.src}; `
        }

        if (entity.type === 'IMAGE') {
          console.log('image', entity.data.src)

          sendData += `image: ${entity.data.src}; `
        }

        if (entity.type === 'embed') {
          sendData += `embed: ${entity.data.url}; `
        }
      }

      for (const block of blocks) {
        if (block.type === EditorBlockType.IMAGE && block.data) {
          const fileSrcArray: string[] = block.data.srcArray
          sendData += fileSrcArray.reduce(
            (accumulator: string, currentValue) =>
              accumulator + `image: ${currentValue}; `,
            ''
          )
          if (block.text.length > 0) {
            sendData += `image_caption: ${block.text}; `
          }
        }
      }

      if (value.IsBlogpost) {
        sendData += value.BlogpostPreviewLink
          ? `Blogpost preview: ${value.BlogpostPreviewLink}; `
          : ''
        sendData += value.BlogpostTitle
          ? `Blogpost title: ${getPostTextFromEditorState(
              value.BlogpostTitle
            )}; `
          : ''
        sendData += value.BlogpostDescription
          ? `Blogpost description: ${getPostTextFromEditorState(
              value.BlogpostDescription
            )}; `
          : ''
      }

      await Promise.all(
        value.IpfsFiles.map(async (field: IIpfsFiles) => {
          sendData += config?.getPrice
            ? `${field.FileType}: ${getPriceFileTestLink}; `
            : getFileData(field)
        })
      )
    })
  )
  return sendData
}

export const getCommentAsString = async (
  list: ICreateComment[],
  config?: { getPrice?: boolean; id?: number }
) => {
  let getPriceFileTestLink =
    'test/test/test/test/test/test/test/test/test/test/test/test/test/test/test' //-◄-If "Get Price" get test link
  let sendData = '' //-◄-Create empty send data
  //-▼-Forming new data
  await Promise.all(
    list.map(async (value: ICreateComment, idx: number) => {
      const commentText = getPostTextFromEditorState(value.Text)
      sendData += `Comment: ${config?.id || idx}; Nickname: @${
        ProfileStore.selectedUser.NickName
      }; ${commentText && `Text: ${commentText}; `}`
      await Promise.all(
        value.IpfsFiles.map(async (field: IIpfsFiles) => {
          sendData += config?.getPrice
            ? `${field.FileType}: ${getPriceFileTestLink}; `
            : getFileData(field)
          // sendData += `${field.FileType}: ${
          //   config?.getPrice ? getPriceFileTestLink : field.FileLink
          // }; `
        })
      )
    })
  )
  return sendData
}

export const getOpenSeaLink = (NetworkType: number, BlockchainId: number) => {
  if (NetworkType === 0) {
    return `${process.env.REACT_APP_OPENSEA_LINK_ETHEREUM}${process.env.REACT_APP_ERC721_ADDRESS_OBJECT}/${BlockchainId}`
  } else if (NetworkType === 1) {
    return `${process.env.REACT_APP_OPENSEA_LINK_POLYGON}${process.env.REACT_APP_ERC721_ADDRESS_OBJECT_MATIC}/${BlockchainId}`
  }
}

export const addAuthToMetaMask = async () => {
  try {
    if (ethereum) {
      await ethereum.request({
        method: 'metamask_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: process.env.REACT_APP_ERC20_ADDRESS_OBJECT,
            symbol: 'AUTH',
            decimals: 8,
            image: AuthIcon,
          },
        },
        id: 20,
      })
    }
  } catch (e) {
    console.log('e', e)
  }
  // else if (MobileWalletConnect.connector?.connected) {
  //   const provider = getWalletConnectProvider()
  //   await provider.enable()
  //   const web3Provider = new ethers.BrowserProvider(provider)
  //   const signer = web3Provider.getSigner()
  //
  //   provider.sendAsync(
  //     {
  //       method: 'metamask_watchAsset',
  //       params: {
  //         type: 'ERC20',
  //         options: {
  //           address: '0x0107c4aaa31940ef88760acb1f32424ca8d8bd80',
  //           symbol: 'AUTH',
  //           decimals: 8,
  //           image: 'https://authencity.infura-ipfs.io/ipfs/QmaR6wfdHvZS4Np7CgYyD7RGuZHeXt3vpNEWrdtDevUFtm'
  //         }
  //       },
  //       id: 20
  //     },
  //   )
  // }
}

// Delete after wc v2 finish
// export const getWalletConnectProvider = () => {
//   return new WalletConnectProvider({
//     rpc: {
//       80001: 'https://matic-mainnet.chainstacklabs.com',
//       137: 'https://matic-mainnet.chainstacklabs.com'
//     },
//     infuraId: 'd36ed4eb25a84b88a323aa593719116c'
//   })
// }
